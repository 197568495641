import React, { useState } from 'react';

import {
    IntroHolder,
    TwoColumns,
    Content,
    Sidebar,
} from '@bahaide/shared/components/InnerPage';

import InnerPage from '../../../components/InnerPage';
import { Link } from 'gatsby';

function encode(data) {
    return Object.keys(data)
        .map(
            (key) =>
                encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
        )
        .join('&');
}

function HDAContact() {
    const [state, setState] = useState({
        isLoading: false,
        submitted: false,
        Gruppenbesuch: false,
        Andere: false,
        Information: false,
        'DSVGO-Check': false,
    });

    function handleChange(e) {
        const target = e.target;
        const value =
            target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        setState((prevState) => ({ ...prevState, [name]: value }));
    }

    function handleSubmit(e) {
        e.preventDefault();
        setState((prevState) => ({ ...prevState, isLoading: true }));
        const form = e.target;
        fetch('/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: encode({
                'form-name': form.getAttribute('name'),
                ...state,
            }),
        })
            .then(() =>
                setState((prevState) => ({
                    ...prevState,
                    isLoading: false,
                    submitted: true,
                }))
            )
            .catch((error) => alert(error));
    }

    return (
        <InnerPage site="hda" title="Kontakt" description="">
            <IntroHolder
                title="Kontakt"
                breadcrumbs={{
                    parent: 'Haus der Andacht',
                    'current-item': 'Kontakt',
                }}
            />

            <TwoColumns>
                <Content>
                    <div>
                        <p>
                            Es gibt die Möglichkeit, an Programmen für
                            Besuchergruppen teilzunehmen, um das Haus der
                            Andacht, seine Geschichte und sein Wirken kennen zu
                            lernen. Jeder, der möchte, kann diese „Oase für
                            tiefes Nachdenken” zum Meditieren und Beten nutzen.
                            Haben Sie noch weitere Fragen zum Haus der Andacht
                            oder möchten Sie Ihren Gruppenbesuch mit uns planen?
                        </p>
                    </div>
                    <div role="form">
                        <form
                            name="hda-contact"
                            method="post"
                            data-netlify="true"
                            data-netlify-honeypot="bot-field"
                            onSubmit={handleSubmit}
                        >
                            {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                            <input
                                type="hidden"
                                name="form-name"
                                value="hda-contact"
                            />
                            <p hidden>
                                <label>
                                    Don’t fill this out:{' '}
                                    <input
                                        name="bot-field"
                                        onChange={handleChange}
                                    />
                                </label>
                            </p>
                            <p>
                                Ich würde gerne
                                <span>
                                    <ul className="unstyled">
                                        <li className="first">
                                            <input
                                                type="checkbox"
                                                name="Information"
                                                checked={state['Information']}
                                                onChange={handleChange}
                                            />
                                            <span className="wpcf7-list-item-label">
                                                Informationen über das Haus der
                                                Andacht und Besuchsmöglichkeiten
                                                erhalten
                                            </span>
                                        </li>
                                        <li>
                                            <input
                                                type="checkbox"
                                                name="Gruppenbesuch"
                                                checked={state['Gruppenbesuch']}
                                                onChange={handleChange}
                                            />
                                            <span className="wpcf7-list-item-label">
                                                einen Gruppenbesuch beim Haus
                                                der Andacht planen und anmelden
                                            </span>
                                        </li>
                                        <li className="last">
                                            <input
                                                type="checkbox"
                                                name="Andere"
                                                checked={state['Andere']}
                                                onChange={handleChange}
                                            />
                                            <span className="wpcf7-list-item-label">
                                                etwas anderes erfahren oder
                                                mitteilen
                                            </span>
                                        </li>
                                    </ul>
                                </span>
                                <span className="your-name">
                                    <input
                                        type="text"
                                        name="Name"
                                        size="40"
                                        aria-required="true"
                                        required
                                        placeholder="Name"
                                        onChange={handleChange}
                                    />
                                </span>
                                <br />
                                <span className="your-email">
                                    <input
                                        type="email"
                                        name="E-Mail"
                                        size="40"
                                        className=""
                                        aria-required="true"
                                        required
                                        placeholder="E-Mail"
                                        onChange={handleChange}
                                    />
                                </span>
                                <br />
                                <span className="your-number">
                                    <input
                                        type="tel"
                                        name="Telefonnummer"
                                        size="40"
                                        className=""
                                        placeholder="Telefonnummer (freiwillig)"
                                        onChange={handleChange}
                                    />
                                </span>
                                <br />
                                <span className="your-message">
                                    <textarea
                                        name="Nachricht"
                                        cols="40"
                                        rows="10"
                                        className=""
                                        aria-required="true"
                                        required
                                        placeholder="Nachricht"
                                        onChange={handleChange}
                                    />
                                </span>
                                <br />
                                <br />
                                <span className="gdpr-check">
                                    <input
                                        type="checkbox"
                                        name="DSVGO-Check"
                                        checked={state['DSVGO-Check']}
                                        onChange={handleChange}
                                        aria-required="true"
                                        required
                                    />
                                    <span className="wpcf7-list-item-label">
                                        Zustimmung zur Datenspeicherung lt.
                                        DSGVO
                                    </span>
                                </span>
                                <br />
                                Ich stimme zu, dass meine Angaben aus dem
                                Kontaktformular zur Beantwortung meiner Anfrage
                                erhoben und verarbeitet werden. Die Daten werden
                                nach abgeschlossener Bearbeitung Ihrer Anfrage
                                gelöscht. Hinweis: Sie können Ihre Einwilligung
                                jederzeit für die Zukunft per E-Mail an
                                info@bahai.de widerrufen. Detaillierte
                                Informationen zum Umgang mit Nutzerdaten finden
                                Sie in unserer{' '}
                                <Link to="/datenschutzerklaerung/">
                                    Datenschutzerklärung
                                </Link>
                                .
                            </p>
                            <p>
                                <input
                                    className={
                                        state.submitted ? 'submitted' : ''
                                    }
                                    type="submit"
                                    value={
                                        state.isLoading
                                            ? '...'
                                            : state.submitted
                                            ? 'Gesendet'
                                            : 'Senden'
                                    }
                                    disabled={
                                        state['DSVGO-Check'] ? false : true
                                    }
                                />
                            </p>
                        </form>
                    </div>
                </Content>
                <Sidebar>
                    <p>
                        Besucherzentrum am Haus der Andacht von Europa
                        <br />
                        Eppsteiner Str. 95
                        <br />
                        65719 Hofheim
                        <br />
                        Tel: 06192 – 901677
                        <br />
                        Email:{' '}
                        <a href="mailto:hausderandacht@bahai.de">
                            hausderandacht@bahai.de
                        </a>
                    </p>
                </Sidebar>
            </TwoColumns>
        </InnerPage>
    );
}

export default HDAContact;
